#project p {
    color: $lightergray;
}

@media only screen and (min-width: 768px) {
    /* For desktop: */
    #project {
        display: flex;
        
    }
    #projectDesc {
        width: 50%;
        margin: 2rem;
    }
    #projectImage {
        width: 50%;
        margin: 2rem;
    }
  }
