

#skills {
    display: flex;
    justify-content: space-evenly;
    background-color: $gray;
    border-radius: 5px;
    padding: 1rem;
}
#skills p {
    margin: 0.25rem;
    text-align: center;
}


@media only screen and (min-width: 768px) {
    /* For desktop: */
        #skills {
            margin: 0 10rem 0 10rem;
        }
  }