// Colours
$h1: cyan;
$black: #010409;
$gray: #161b22;
$lightergray: #8B949E;

// Spacing
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$desktop-breakpoint: 45rem;
