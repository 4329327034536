@font-face { 
  font-family: "roboto-regular"; 
  src: url('../fonts/Roboto-Regular.ttf'); 
}
@font-face { 
  font-family: "roboto-bold"; 
  src: url('../fonts/Roboto-Bold.ttf'); 
}
@font-face { 
  font-family: "roboto-light"; 
  src: url('../fonts/Roboto-Light.ttf'); 
}
@font-face { 
  font-family: "roboto-italic"; 
  src: url('../fonts/Roboto-Italic.ttf'); 
}

body {
    font-family: "roboto-regular";
    background-color: $black;
    color: white;
  }
b {
  font-family: "roboto-bold";
}
h1{
  font-family: "roboto-light";
}
i {
  font-family: "roboto-italic";
}
a {
  color: #388bfd;
}
hr {
  color: white;
}