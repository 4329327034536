#banner {
    margin-top: 2rem;
    text-align: center;
}
#banner h1 {
    color: $h1;
}
#banner h1 b {
    color: white;
}
