#main {
    margin: 2rem 1rem 2rem 1rem;
}
#main h1 {
    text-align: center;
    color: $h1;
    margin: 2rem 0 0.5rem 0;
}
#info {
    margin: 1rem 2rem 1rem 2rem;
    background-color: $gray;
    padding: 0.25rem 1rem 0.25rem 1rem;
    border-radius: 5px;
}
#main p {
    line-height: 2em;
}


@media only screen and (min-width: 768px) {
    /* For desktop: */
    #info {
        margin: 1rem 10rem 1rem 10rem;
    }
    #intro {
        margin: 1rem 10rem 1rem 10rem;
    }
  }

